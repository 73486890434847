<template>
  <div class="plan-list">
    <div class="header">
      <div class="left" @click="onGoBack">
        <img :src="goBackImg" />
      </div>
      <div class="center">
        学习规划系统
      </div>
      <div class="right"></div>
    </div>
    <div class="calendar-block">
      <div class="calendar-container">
        <div class="calendar">
          <div class="date-time">
            <label class="time-label">开始时间</label>
            <div class="date-time-select" @click="onPickDate('start')">{{ startDateText }}</div>
          </div>
          <div class="date-time">
            <label class="time-label">结束时间</label>
            <div class="date-time-select" @click="onPickDate('end')">{{ endDateText }}</div>
          </div>
        </div>

        <div class="line"></div>
      </div>
    </div>
    <div class="task-block">
      <div class="task-header">
        <div class="task-label" @click="switchTaskType(false)">
          <div v-bind:class="{ taskActive: hasFinish == false, 'task-text': hasFinish == true }">进行中</div>
          <div class="task-line" v-if="hasFinish == false"></div>
        </div>
        <div class="task-label" @click="switchTaskType(true)">
          <div v-bind:class="{ taskActive: hasFinish == true, 'task-text': hasFinish == false }">已完成</div>
          <div class="task-line" v-if="hasFinish == true"></div>
        </div>
      </div>
      <div class="task-body" v-if="hasFinish == false">
        <div class="task-item" v-for="item in taskList" :key="item.id">
          <div class="task-item-left">
            <img :src="todoIcon" @click="setFinish(item)" />
          </div>
          <div class="task-item-right">
            <div class="task-item-title" @click="onViewTask(item)">
              {{ item.title }}
            </div>
            <div class="task-item-time">
              {{ item.timeStr }}
            </div>
          </div>
        </div>
      </div>
      <div class="task-body" v-if="hasFinish == true">
        <div class="task-finish-item" v-for="item in taskList" :key="item.id">
          <div class="task-finish-item-left">
            <img :src="todoDoneIcon" />
          </div>
          <div class="task-finish-item-right">
            <div class="task-finish-item-title" @click="onViewTask(item)">
              {{ item.title }}
            </div>
            <div class="task-finish-item-time">
              {{ item.timeStr }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-body"></div>
    </div>
    <div class="add-task" @click="addTask" v-if="showStartPicker == false && showEndPicker == false">
      <img :src="addTaskIcon" />
    </div>
    <van-datetime-picker
      v-if="showStartPicker"
      v-model="startDate"
      type="year-month"
      @cancel="cancelPicker"
      @confirm="confirmPicker"
      title="选择年月"
    />
    <van-datetime-picker
      v-if="showEndPicker"
      v-model="endDate"
      type="year-month"
      @cancel="cancelPicker"
      @confirm="confirmPicker"
      title="选择年月"
    />
  </div>
</template>

<script>
import planPageWeek from "@/components/planPageWeek.vue";
import planPageMonth from "@/components/planPageMonth.vue";
import dayjs from "dayjs";
import { userLearnPlanApi } from "@/api/userLearnPlanApi.js";
import { DatetimePicker } from "vant";
import { Notify } from "vant";
export default {
  components: {},
  data() {
    return {
      goBackImg: require("@/assets/images/go-back.png"),
      todoIcon: require("@/assets/images/user-learn-plan/todo.png"),
      todoDoneIcon: require("@/assets/images/user-learn-plan/todo-done.png"),
      addTaskIcon: require("@/assets/images/user-learn-plan/add-task.png"),

      hasFinish: false,
      taskList: [],

      showStartPicker: false,
      showEndPicker: false,
      startDate: new Date(),
      endDate: new Date(),
      startDateText: "",
      endDateText: "",
      startTime: dayjs().valueOf(), // 有效的时间戳
      endTime: dayjs().valueOf(),
    };
  },
  methods: {
    onGoBack() {
      this.$router.push({
        path: "/profile",
      });
    },
    initData() {
      let curMonth = dayjs();
      this.startDate = new Date(curMonth.startOf("month").valueOf());
      let nextMonth = curMonth.add(1, "month");
      this.endDate = new Date(nextMonth.endOf("month").valueOf());

      this.onDateChange();
    },
    onDateChange() {
      let curMonth = dayjs(this.startDate);
      this.startDate = new Date(curMonth.startOf("month").valueOf());
      let nextMonth = dayjs(this.endDate);
      this.endDate = new Date(nextMonth.endOf("month").valueOf());

      this.startTime = dayjs(this.startDate);
      this.endTime = dayjs(this.endDate);

      this.startDateText = this.startTime.format("YYYY-MM-DD");
      this.endDateText = this.endTime.format("YYYY-MM-DD");
    },
    // 打开vant的时间选择器
    onPickDate(dateType) {
      console.log(dateType);

      if (dateType == "start") {
        this.showStartPicker = true;
      } else {
        this.showEndPicker = true;
      }
    },
    cancelPicker() {
      this.showStartPicker = false;
      this.showEndPicker = false;
    },
    confirmPicker() {
      this.onDateChange();
      this.cancelPicker();
      this.onQuery();
    },
    onViewTask(item) {
      this.$router.push({
        path: "/userLearnPlan/view",
        query: {
          id: item.id,
        },
      });
    },
    changeCalendarMode(val) {
      //console.log("changeCalendarMode", val);
      this.calendarMode = val;
    },
    switchTaskType(val) {
      this.hasFinish = val;
      this.onQuery();
    },
    setFinish(item) {
      userLearnPlanApi
        .setFinish(item.id)
        .then((ret) => {
          let { code, data } = ret;
          if (code == 0 && data) {
            for (let i = 0; i < this.taskList.length; i++) {
              if (this.taskList[i].id == item.id) {
                this.taskList.splice(i, 1);
                break;
              }
            }
          }
        })
        .catch((err) => {});
    },
    addTask() {
      this.$router.push({
        path: "/userLearnPlan/addEdit",
      });
    },
    onQuery() {
      let startTimeTs = this.startTime.valueOf();
      let endTimeTs = this.endTime.valueOf();
      if (endTimeTs < startTimeTs) {
        Notify({
          type: "warning",
          message: "结束时间得大于开始时间吧？",
        });
        return;
      }
      userLearnPlanApi
        .query(this.hasFinish, startTimeTs, endTimeTs)
        .then((ret) => {
          //console.log("#", ret);
          let { code, datas } = ret;
          if (code == 0 && datas) {
            this.taskList = this.wrapTaskList(datas);
            //console.log("task list", this.taskList);
          }
        })
        .catch((err) => {});
    },
    wrapTaskList(inputList) {
      for (let i = 0; i < inputList.length; i++) {
        let item = inputList[i];
        let dayStart = dayjs(Number(item.startTime));
        let dayEnd = dayjs(Number(item.endTime));
        let timeStr = dayStart.format("YYYY.MM.DD HH:mm") + " - " + dayEnd.format("MM.DD HH:mm");
        item.timeStr = timeStr;
      }
      return inputList;
    },
  },
  mounted() {
    this.initData();
    this.onQuery();
  },
  watch: {
    queryTimeWeek() {
      console.log("queryTimeWeek", this.queryTimeWeek);
      this.onQuery();
    },
    queryTimeMonth() {
      console.log("queryTimeMonth", this.queryTimeMonth);
      this.onQuery();
    },
  },
};
</script>

<style lang="less" scoped>
.plan-list {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100vh;
  .header {
    height: 62px;
    flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      img {
        margin-left: 16px;
        height: 23px;
        width: auto;
      }
    }
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 20px;
      font-weight: 500;
      color: #323233;
      letter-spacing: 0.44px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
    }
    .right {
      width: 27px;
    }
  }
  .calendar-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    .calendar-container {
      border-radius: 8px 8px 0px 0px;
      background: #ffffff;
      margin: 14px 0px 0px 0px;
      width: 344px;
      .calendar {
        margin: 12px 0px 0px 0px;
        font-size: 14px;
        color: #242424;
        font-weight: 500;
        .date-time {
          display: flex;
          flex-direction: row;
          align-items: center;
          .time-label {
            margin: 10px;
          }
          .date-time-select {
            margin: 10px;
            padding: 0px 0px 0px 10px;
            background: #efefef;
            border-radius: 8px;
            border-radius: 8px;
            width: 96px;
            height: 30px;
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
      }
      .line {
        margin: 10px 10px;
        height: 1px;
        border-bottom: 1px solid rgb(239, 239, 239);
      }
    }
  }
  .task-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    overflow-y: scroll;
    //background: #ffffff;
    .task-header {
      height: 42px;
      width: 344px;
      display: flex;
      flex-direction: row;
      background: #ffffff;
      .task-label {
        height: 36px;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        align-items: center;
        margin: 0px 0px 0px 20px;
        .taskActive {
          font-weight: 500;
          color: #242424;
          letter-spacing: 0;
        }
        .task-text {
          color: #9298a4;
          letter-spacing: 0;
        }
        .task-line {
          margin: 6px 0px 0px 0px;
          height: 4px;
          width: 24px;
          background: #4d52d1;
          border-radius: 16px;
        }
      }
    }
    .task-body {
      overflow: hidden;
      overflow-y: scroll;
      flex: 1;
      width: 344px;
      background: #ffffff;
      .task-item {
        height: 50px;
        width: 324px;
        margin: 10px;
        background: #fafafa;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        .task-item-left {
          margin: 8px 8px 0 8px;
          img {
            width: 16px;
            height: 16px;
          }
        }
        .task-item-right {
          display: flex;
          flex-direction: column;
          .task-item-title {
            width: 285px;
            font-size: 16px;
            color: #505051;
            letter-spacing: 0.36px;
            padding: 6px 0px 4px 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .task-item-time {
            font-size: 11px;
            color: #9298a4;
            letter-spacing: 0.24px;
            margin: 0px 0px 8px 0px;
          }
        }
      }
      .task-finish-item {
        height: 50px;
        width: 324px;
        margin: 10px;
        background: #efefef;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        .task-finish-item-left {
          margin: 8px 8px 0 8px;
          img {
            width: 16px;
            height: 16px;
          }
        }
        .task-finish-item-right {
          display: flex;
          flex-direction: column;
          .task-finish-item-title {
            width: 285px;
            font-size: 16px;
            color: #9298a4;
            text-decoration: line-through;
            letter-spacing: 0.36px;
            padding: 6px 0px 4px 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .task-finish-item-time {
            font-size: 11px;
            color: #9298a4;
            letter-spacing: 0.24px;
            margin: 0px 0px 8px 0px;
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    .footer-body {
      width: 344px;
      height: 20px;
      background: #ffffff;
    }
  }
  .add-task {
    position: fixed;
    z-index: 1;
    left: 300px;
    bottom: 48px;
    img {
      width: 44px;
      height: 44px;
    }
  }
}
</style>
